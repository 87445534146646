import React, { useEffect, useState } from 'react';
import CareerCss from './career.module.css';
import BannerImg from '../img/career/CAREER.png';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import { BiRupee, BiArrowBack } from "react-icons/bi";
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Col, Container, Row, Button } from 'react-bootstrap';
import InputField from './InputField';
import { Helmet } from 'react-helmet';

import noVacancy from '../img/no-vacancy.jpg'
import {formControlClasses} from '@mui/material';


function Career() {

  const [vacanciesList, setVacanciesList] = useState()
  const currentDay = Date.now()
  let activeUp = null

  useEffect(()=>{
    fetch('https://swaadhyayan.com/swaBlog/api/career/career.php')
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      if(data.status=="success"){
        setVacanciesList(data.cList)
      }else{
        alert('There are Currently no Vacancies Available!')
      }

    });

  },[])

  const data = [
    {
      id: 1,
      position: 'Android Developer',
      experience: '1-4 Years',
      location: 'New Delhi',
      discrip: 'We are looking for an Android Developer who possesses a passion for pushing mobile technologies to the limits. This Android app developer will work with our team of talented engineers to design and build the next generation of our mobile applications. Android programming works closely with other app development and technical teams.',
      postDay: '5 days ago'
    },
    {
      id: 2,
      position: 'IOS App Developer',
      experience: '1-10 Years',
      location: 'New Delhi',
      discrip: 'We are looking for an iOS developer who possesses a passion for pushing mobile technologies to the limits and will work with our team of talented engineers to design and build the next generation of our mobile applications. ',
      postDay: '25 days ago'
    },
    {
      id: 3,
      position: 'Software Trainee',
      experience: '1-4 Years',
      location: 'New Delhi',
      discrip: 'We are looking for a motivated Software Engineer Intern to join our team! As a Software Engineer Intern, you will collaborate with our senior leaders on assignments. ',
      postDay: '30+ days ago'
    },
    {
      id: 4,
      position: 'PHP Developer',
      experience: '1-4 Years',
      location: 'New Delhi',
      discrip: 'We are looking for a PHP Developer who is dedicated to the craft, writes code that he/she is proud of and can hit the ground running. ',
      postDay: '10 days ago'

    },
    {
      id: 5,
      position: 'Sr. PHP Developer',
      experience: '1-12 Years',
      location: 'New Delhi',
      discrip: 'We are looking for a Sr. PHP Developer who is dedicated to the craft, writes code that he/she is proud of and can hit the ground running. ',
      postDay: '5 days ago'
    },
    {
      id: 6,
      position: 'React Native Developer',
      experience: '1-4 Years',
      location: 'New Delhi',
      discrip: 'We are looking for a React Native developer interested in building performant mobile apps on both the iOS and Android platforms. You will be responsible for architecting and building these applications, as well as coordinating with the teams responsible for other layers of the product infrastructure. Building a product is a highly collaborative effort, and as such, a strong team player with a commitment to perfection is required. ',
      postDay: '22 days ago'
    },
  ];


  const inputs = [
    {
      id: 1,
      name: "uName",
      type: "text",
      placeholder: "Enter your full Name",
      label: "Full Name",
      autoComplete:'off',
      required: true,
    },
    {
      id: 2,
      name: "contactNo",
      type: "number",
      placeholder: "Enter your mobile number",
      label: "Mobile Number",
      autoComplete:'off',
      required: true,
    },
    {
      id: 3,
      name: "email",
      type: "email",
      placeholder: "Enter your email address",
      label: "Email Id",
      autoComplete:'off'
    },
    {
      id: 4,
      name: "jobTitle",
      type: "text",
      placeholder: "Enter job title",
      label: "Job Title",
      required: true,
    },
    {
      id: 5,
      name: "file",
      type: "file",
      placeholder: "",
      label: "Upload CV",
      required: true,
    },
  ];

const [showPopup, setShowPopup] = useState(false)
const [selectedData, setSelectedData] = useState({data:null, sendData:null})
const [submitStatus, setSubmitStatus] = useState({status:false, msg:""})
const [contactNum, setContactNum] = useState([])

// offCanvas//
const [show, setShow] = useState(false);
const handleClose = () => setShow(false);
// const handleShow = () => setShow(true);
// offCanvas//

function applynow(item){
  setShow(true)
  // if(currentDay>activeUp){
  //   alert('post expire')
  // }else{
  // }
}
const [values, setValues] = useState({
    uName: "",
    contactNo: "",
    email: "",
    jobTitle: "",
    file: "",
  });

  function ReadMore(item, sendData){
    setShowPopup(true)
    setSelectedData((prev)=>{
      return{...prev, data:item, sendData:sendData}
    })
  }

  function closePopup() {
    setShowPopup(false)
  }
  // const temp = [];
  // console.log('start : ', temp)

  const onChange = (e) => {
    // console.log(e.target.name, '----------------')
    if(e.target.value.length <= 10 && e.target.name=='contactNo'){
      if(contactNum.length>0){
        contactNum.splice(0,1)
      }
        contactNum.push(e.target.value)
    }
    if(e.target.name=="contactNo" && e.target.value.length>10){
        e.target.value = contactNum.toString()
    }else{
    }
    setValues((prev)=>{
      return{...prev, [e.target.name]:e.target.name=="file"?e.target.files[0]:e.target.value}
    })
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append('uName', values.uName);
    formData.append('contactNo', values.contactNo);
    formData.append('email', values.email);
    formData.append('jobTitle', selectedData.data.jobTitle);
    formData.append('file', values.file);

    let payload = {
       method: "POST",
       body: formData
    };

    console.log(formData, 'formData------------------455')

    fetch("https://swaadhyayan.com/swaBlog/api/career/careerapply.php", payload)
       .then((response) => response.json())
       .then((data) => {
        if(data.status=="success"){
          setSubmitStatus((prev)=>{
            return{...prev, status:true, msg:data.message, subMsg:data.subMessage}
          })
          setValues((prev)=>{
            return{...prev,
              uName: "",
              contactNo: "",
              email: "",
              jobTitle: "",
              file: "",
            }
          })
          setTimeout(()=>{
            setShow(false);
            setSubmitStatus((prev)=>{
              return{...prev, status:false, msg:"", subMsg:""}
            })
          },3000)
        }else if(data.status=="failed"){
          setSubmitStatus((prev)=>{
            return{...prev, status:true, msg:data.message,}
          })
          setValues((prev)=>{
            return{...prev,
              uName: "",
              contactNo: "",
              email: "",
              jobTitle: "",
              file: "",
            }
          })
          setTimeout(()=>{
            setShow(false);
            setSubmitStatus((prev)=>{
              return{...prev, status:false, msg:""}
            })
          },3000)
        }
       })
       .catch((err)=>{
        console.log(err)
       })
       .finally(()=>{

       })
       ;
  };

  return (
    <div className={CareerCss.carrerBk}>
      <Helmet>
        <title>Careers options for Information Technology, HR, Swa-Adhyayan</title>
        <meta name="description" content="- Give Wings to Your Career in the field of IT by grabbing opportunity of joining us an Mobile App, Web Developer and Software Trainee Professional- Swa-Adhyayan" />
        <meta property="og:title" content="Careers options for Information Technology, HR, Swa-Adhyayan" /> 
        <meta property="og:description" content="Give Wings to Your Career in the field of IT by grabbing opportunity of joining us an Mobile App, Web Developer and Software Trainee Professional- Swa-Adhyayan"/> 
        <meta property="og:url" content="https://swaadhyayan.com/Event" /> 
        <meta property="og:site_name" content="Swa Adhyayan" /> 
        <meta property="og:image" content="https://swaadhyayan.com/static/media/CAREER.95735547.png" /> 
        <meta name="twitter:site" content="@swaadhyayan"/> 
        <meta name="twitter:title" content="Careers options for Information Technology, HR, Swa-Adhyayan"/>
        <meta name="twitter:description" content="Give Wings to Your Career in the field of IT by grabbing opportunity of joining us an Mobile App, Web Developer and Software Trainee Professional- Swa-Adhyayan" />
        <meta name="twitter:image" content="https://swaadhyayan.com/static/media/CAREER.95735547.png" />
      </Helmet>
      {/* Banner Section */}
      <div className={CareerCss.banner}>
        <img className={CareerCss.bannerImg} src={BannerImg}/>
      </div>
      {/* Banner Section */}
      {/* Second Section */}
      <div className={CareerCss.bgdiv}>
        <Container>
          {!showPopup && vacanciesList!=undefined?
            <Row className="" data-aos="zoom-in" data-aos-duration="1500">
              {/* <div className={CareerCss.Heading}>CAREER OPPORTUNITIES </div>*/}
              {vacanciesList.map((item, index) => {
                let shortDiscription = ''
                let postDayCount = null
                let currentDate = Date.now()
                let activeUpTo = new Date(item.activeUpto).getTime()
                let activeDate = new Date(item.activeFrom).getTime()
                let difference = currentDate - activeDate
                let total_seconds = parseInt(Math.floor(difference / 1000 / 60 / 60 / 24));
                // setActiveUp(activeUpTo)
                activeUp = activeUpTo
                postDayCount = total_seconds
                if (item.jobDescription.length>95){
                  shortDiscription = item.jobDescription.substring(0, 92) +'...'
                }
                const sendData = {
                        "postDayCount":postDayCount,
                        "currentDate":currentDate,
                        "activeUpTo":activeUpTo
                }


                return (
                  <Col className="col-md-4 col-sm-6 col-12 mt-3" key={item.careerID}>
                    <div className={CareerCss.containerBox}>
                      <div className={CareerCss.jobHead}>{item.jobTitle}</div>
                      <div className={CareerCss.experience}>
                        <div className={CareerCss.item}><WorkOutlineIcon style={{ fontSize: '20px', color: '#b1b0b0', marginRight: '6px' }} />{item.experience}</div>
                        <div className={CareerCss.item}><BiRupee style={{ fontSize: '23px', color: '#b1b0b0', marginRight: '6px' }} />Not disclosed</div>
                        <div className={CareerCss.item}><LocationOnOutlinedIcon style={{ fontSize: '20px', color: '#b1b0b0', marginRight: '6px' }} />{item.jobPlace}</div>
                      </div>
                      <div className={CareerCss.discription}>
                        <div className="">Job discription:</div>
                        <div className="">{shortDiscription}</div>
                      </div>
                      <div className={CareerCss.btnDiv}>
                        <div className={CareerCss.postDate}>{currentDate>activeUpTo?"Expire":`${postDayCount} days ago`}</div>
                        <div>
                          <button className='btn btn-primary btn-sm' onClick={() => ReadMore(item, sendData)}>Read More</button>
                        </div>
                      </div>

                    </div>

                  </Col>
                )
              })}
            </Row>:null
          }

          {showPopup &&
            <div className={CareerCss.popup1}>
              <Container className={CareerCss.innerDiv}>
                <div className={CareerCss.jobPosition}>
                  <div className={CareerCss.buttonDiv}
                    onClick={closePopup}
                  >
                    <BiArrowBack/>BACK
                  </div>
                  <div className={CareerCss.positionHead}>{selectedData.data.jobTitle}</div>
                  <div className={CareerCss.items}>

                    <div className="">
                      <div className={CareerCss.point}><WorkOutlineIcon style={{ fontSize: '20px', color: '#b1b0b0', marginRight: '6px'}}/>{selectedData.data.experience}</div>
                      <div className={CareerCss.point}><BiRupee style={{ fontSize: '23px', color: '#b1b0b0', marginRight: '6px' }} />{selectedData.data.salaryWages}</div>
                      <div className={CareerCss.point}><LocationOnOutlinedIcon style={{ fontSize: '20px', color: '#b1b0b0', marginRight: '6px' }} />{selectedData.data.jobPlace}</div>
                    </div>

                    <div className={CareerCss.applyBtn}>
                      {/* <button className='btn btn-secondary' data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">APPLY</button> */}

                     <Button variant="primary" onClick={()=>applynow(selectedData)}>APPLY</Button>

                    </div>
                  </div>
                  <div className={CareerCss.jobpostDate}>
                    <div style={{ borderRight: '1px solid gray', padding: '0 10px'}}>Posted: <span style={{ fontWeight: '500', color:selectedData.sendData.currentDate>selectedData.sendData.activeUpTo?"red":"black" }}>{selectedData.sendData.currentDate>selectedData.sendData.activeUpTo?"Expire":`${selectedData.sendData.postDayCount} days ago`}</span></div>
                    <div style={{ padding: '0 10px' }}>Openings: <span style={{ fontWeight: '500' }}>{selectedData.data.totalRequirement}</span></div>

                  </div>
                </div>


                <div className={CareerCss.jobDiscription}>
                  <div className={CareerCss.subHead}>Job Description:</div>
                  <div className="pb-2">{selectedData.data.jobDescription}</div>

                  <div className={CareerCss.subHead}>Requirements and Skills:</div>
                  <div className="pb-2">{selectedData.data.softwareKnowledge}</div>

                </div>
                <div className={CareerCss.companyProfile}>
                  <div className={CareerCss.subHead}>About Company</div>
                  <div className="">Swa-Adhyayan is a product or package designed and led by a team of visionaries, educators and professionals to captivate the attention of learners, improve their talents and escalate their intellects. This platform went digital at a time when the teaching-learning process turned more towards online learning. It helped many institutions to educate students continuously with its hybrid learning.<br />
                    This Adaptive Digital Learning Platform (ADLP) includes features such as SEPT (Swa-Adhyayan Entrant’s Profiling Test), Swa-learning, Swa-assessment, Swa-sharing, Homework app, and Reports. The Assessment for classes 1 to 8 generates multiple tests and reports and the progress cards of the children based on numerous parameters. It is supported by textbooks, workbooks, teachers’ resource manuals, competitive kits, NCERT kits, CBSE Pariksha, etc. Its features, ‘Learning by Gamification’ improves the kinesthetic skills of the learners, adding to experiential learning or hands-on learning. The purpose of Swa-Adhyayan is to deliver this product globally and to ensure that learners acquire better learning outcome by using the resources shared in this platform.</div>
                </div>

              </Container>
            </div>
          }

        </Container>
      </div>
      {/* Second Section */}

      <Offcanvas show={show} onHide={handleClose} backdrop="static" placement = 'end'>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Apply for: {selectedData?.data?.jobTitle.length>23?selectedData?.data?.jobTitle.substring(0,20) + '...':selectedData?.data?.jobTitle} </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className={CareerCss.formContainer}>
              <form onSubmit={handleSubmit} action="https://formspree.io/f/xlekdown" method="POST">
                  {inputs.map((item, index)=>{
                    return(
                      <InputField
                        key = {item.id}
                        {...item}
                          value = {item.name=="jobTitle"?selectedData?.data?.jobTitle:values[item.userName]}
                          disabled= {item.name=="jobTitle"?"disabled":null}
                          onChange = {onChange}
                        />
                    )
                  })}
                {/* <div className={CareerCss.inputContainer}>
                  <div className="">Job description<span className={CareerCss.formstartMark} >*</span></div>
                  <textarea className={CareerCss.inputStyle} required name="Message"></textarea>
                </div> */}
                <Button  style={{width:'100%'}} type="submit" variant="primary">Submit</Button>

                {submitStatus.status &&
                <div style={{padding:20, marginTop:"15px"}}>
                    <div style={{textAlign:'center', fontSize:"18px", fontWeight:'bold', color: submitStatus?.subMsg!=undefined?'green':"red"}}>{submitStatus.msg}</div>
                    {submitStatus?.subMsg!=undefined &&
                      <div style={{textAlign:'center'}}>{submitStatus.subMsg}</div>
                    }
                </div>

                }

              </form>

          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}
export default Career;
