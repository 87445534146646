import React from 'react'
import CareerCss from './career.module.css'

const InputField = (props) => {
  const {label, errorMessage, onChange, id, ...inputProps} = props;
  return (
    <div className={CareerCss.inputContainer}>
    <div className="">{label}<span className={CareerCss.formstartMark} >*</span></div>
    <input
          className={CareerCss.inputStyle}
          {...inputProps}
          onChange={onChange}
          />
    <span>{errorMessage}</span>
  </div>
  )
}

export default InputField